import React from "react"
import { AllSiding } from "../graphql/AllSidingQuery"
import SwiperProducts from "./SwiperProducts"

const SidingCarousel = () => {
  // console.log(AllSiding())

  return <SwiperProducts data={AllSiding()} link />
  // return "hi"
}

export default SidingCarousel
