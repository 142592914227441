import React from "react"
import { AllWorks } from "../graphql/WorksSmallQueries"
import { PartnersText } from "../graphql/QueryById"

const LogoSlider = () => {
  const works = AllWorks()
  // console.log(works)

  function getUniqueCompanies(array) {
    let uniqueArrayName = []
    let uniqueArray = []

    array.forEach((item, index) => {
      if (uniqueArrayName.indexOf(item.company) === -1) {
        uniqueArrayName.push(item.company)
        uniqueArray.push(array[index])
      }
    })
    return uniqueArray
  }

  const companies = getUniqueCompanies(works)

  const companyLogos = companies.map((item, i) => {
    const { company, companyLogo, projectLogo } = item
    return (
      <div
        key={`${company} logo`}
        className="relative z-0 w-auto h-20 group filter sm:grayscale sm:hover:grayscale-0"
      >
        {companyLogo}
        <div className="absolute hidden px-4 py-2 -mx-10 text-center transition rounded shadow-lg -bottom-16 bg-brand-light md:group-hover:flex group-hover:z-50">
          {company}
        </div>
      </div>
    )
  })

  const companiesWithProjectLogo = works.filter(project => project.projectLogo)

  const projectLogos = companiesWithProjectLogo.map((item, i) => {
    const { company, projectLogo } = item
    return (
      <div
        key={i}
        className="relative z-0 w-auto h-20 group filter sm:grayscale sm:hover:grayscale-0"
      >
        {projectLogo}
        <div className="absolute hidden px-4 py-2 -mx-10 text-center transition rounded shadow-lg -bottom-16 bg-brand-light md:group-hover:flex group-hover:z-50 focus:hidden">
          {company}
        </div>
      </div>
    )
  })

  const logos = [...companyLogos, ...projectLogos]

  return (
    <div className="my-10 bg-gradient-to-b from-gray-50 to-transparent">
      <div className="max-w-screen-lg py-10 mx-2 md:mx-auto">
        <div className="flex items-center justify-center px-4 mx-4 font-extrabold text-center uppercase md:mb-4 md:pb-8">
          {PartnersText()}
        </div>
        <div className="relative grid flex-wrap items-center max-w-screen-md grid-cols-3 gap-4 p-10 lg:mx-auto md:gap-12 md:grid-cols-6 justify-items-center md:p-0 sm:mx-8 sm:gap-10 sm:grid-cols-4 lg:gap-10">
          {logos}
        </div>
      </div>
    </div>
  )
}

export default LogoSlider
