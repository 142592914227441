import React from "react"
import { AllRaisedFloors } from "../graphql/AllRaisedFloorQuery"
// import Swiper from "react-id-swiper"
// import Swiper from "./Swiper"
import SwiperProductLink from "./SwiperProductLink"
import SwiperProducts from "./SwiperProducts"
import { AllWallpapers } from "../graphql/WallpaperPageFullQuery"

const RailsedFloorCarousel = () => {
  // console.log(AllRaisedFloors())

  // return <Swiper data={data} />

  return <SwiperProducts data={AllRaisedFloors()} link />
  // return <SwiperProducts data={AllWallpapers()} link />

  // return <SwiperProductLink data={AllRaisedFloors()} />
  // return "hi"
}

export default RailsedFloorCarousel
