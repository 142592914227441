import React, { useContext, useEffect, useRef, useState } from "react"
import { GatsbyContext } from "../context/context"
import { Link } from "gatsby"
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/solid"
import { MoreText } from "../graphql/QueryById"
// import Swiper core and required modules
import SwiperCore, {
  Navigation,
  Pagination,
  A11y,
  Lazy,
  Autoplay,
  EffectFade,
} from "swiper"
import "../styles/swiper.css"
import bgImage from "../images/contemporary_china.png"

import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/swiper.min.css"
import "swiper/components/effect-fade/effect-fade.min.css"
import "swiper/components/navigation/navigation.min.css"
import "swiper/components/pagination/pagination.min.css"

// install Swiper modules
SwiperCore.use([A11y, Lazy, Autoplay, Navigation, EffectFade])

const HomePageSlider = ({ data }) => {
  const { isMegaMenuOpen, showMega, hideMega, modalMenuOpen } = useContext(
    GatsbyContext
  )
  const prevRef = useRef(null)
  const nextRef = useRef(null)

  const swiperRef = useRef(null)

  const slides = data.map((item, index) => {
    const { name, image, slug, md, categoryUrl, tovch } = item
    return (
      <SwiperSlide key={index} className="group">
        <div className="relative antialiased md:mx-4">
          <div className="grid h-120">
            <div className="z-50 flex flex-col items-center justify-end mb-0 md:mb-10 md:items-start md:justify-end md:bottom-10 md:ml-10">
              <Link
                to={`/${categoryUrl}`}
                className="px-8 py-4 bg-white rounded bg-opacity-90"
              >
                {name && (
                  <div className="flex mb-1 text-xl font-bold leading-snug tracking-widest uppercase sm:text-xl decoration-clone">
                    {name}
                  </div>
                )}
                {tovch && (
                  <div className="max-w-sm mt-1 text-sm leading-snug sm:text-base line-clamp-2">
                    {tovch}
                  </div>
                )}
                <div className="pt-2 text-sm font-semibold ">
                  {MoreText()}...
                </div>
              </Link>
             
            </div>
            <div className="absolute z-0 grid object-cover w-full h-full overflow-hidden rounded-md ">
              <div className="flex object-cover w-full h-full">{image}</div>
            </div>
          </div>
        </div>
        <div className="h-2"></div>
      </SwiperSlide>
    )
  })

  function stopAutoplay() {
    swiperRef.current.swiper.autoplay.stop()
  }


  useEffect(() => {
    if (isMegaMenuOpen || modalMenuOpen) {
      swiperRef.current.swiper.autoplay.stop()
    } else {
      swiperRef.current.swiper.autoplay.start()
    }
  }, [isMegaMenuOpen, modalMenuOpen])
 

  return (
    <div>
      <div
        className={`relative group -mx-5 max-w-screen-lg md:mx-auto py-2 antialiased ${
          isMegaMenuOpen ? "opacity-20" : ""
        }`}
        onMouseEnter={() => {
          stopAutoplay()
        }}
        onMouseLeave={() => {
          swiperRef.current.swiper.autoplay.start()
        }}
      >
        <Swiper
          ref={swiperRef}
          spaceBetween={3}
          slidesPerView={1}
          // navigation
          // onSwiper={swiper => console.log(`${swiper} swiper new initialized`)}
          // onSlideChange={() => console.log("slide change")}
          autoplay={{
            delay: 8500,
            // disableOnInteraction: true,
          }}
          loop={true}
          // effect={"fade"}
          // effect={"cube"}
          // cubeEffect={{
          //   shadow: true,
          //   slideShadows: true,
          //   shadowOffset: 20,
          //   shadowScale: 0.94,
          // }}
          speed={500}
          lazy={true}
          // Meduushtei hack
          navigation={{
            prevEl: prevRef.current ? prevRef.current : undefined,
            nextEl: nextRef.current ? nextRef.current : undefined,
          }}
          onInit={swiper => {
            swiper.params.navigation.prevEl = prevRef.current
            swiper.params.navigation.nextEl = nextRef.current
            swiper.navigation.update()
          }}
        >
          {slides}
          

          <div className="flex items-center justify-center">
            <button ref={prevRef} className="outline-none focus:outline-none">
              <ChevronLeftIcon className="w-12 h-12 p-2 text-gray-800 rounded-full outline-none hover:text-black hover:bg-white focus:outline-none focus:border-transparent focus:bg-transparent" />
            </button>
            <button ref={nextRef} className="outline-none focus:outline-none">
              <ChevronRightIcon className="w-12 h-12 p-2 text-gray-800 rounded-full outline-none hover:text-black hover:bg-white focus:outline-none focus:border-transparent" />
            </button>
          </div>
        </Swiper>
      </div>
    </div>
  )
}

export default HomePageSlider
