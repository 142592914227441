import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { CurrentLanguage } from "../components/MenuItems"
import SingleRaisedFloorQuery from "./SingleRaisedFloorQuery"
import SingleProductQuery from "./SingleProductQuery"

export const AllRaisedFloors = () => {
  const { AllRaisedFloors } = useStaticQuery(query)
  // const allFloors = []

  // AllRaisedFloors.nodes.forEach((item, index) => {
  //   return allFloors.push(SingleProductQuery(item))
  // })

  // return allFloors
  return AllRaisedFloors.nodes
    .map((item, index) => {
      return SingleProductQuery(item)
    })
    .filter(item => !item.hide)
    .sort(function (a, b) {
      if (a.name < b.name) {
        return -1
      }
      if (a.name > b.name) {
        return 1
      }
      return 0
    })

  // return [1, 2, 3, 4, 5]

  // return AllRaisedFloors.nodes.map((item, index) => {
  //   // const id = item?.id ?? undefined
  //   // const path = item?.data ?? undefined
  //   const airtable = item ?? undefined

  //   return SingleProductQuery(airtable)
  //   // return SingleProductQuery(path)
  //   // return SingleRaisedFloorQuery(path)
  //   // return (
  //   //   <div key={index}>
  //   //     <SingleProductQuery airtable={airtable} />
  //   //   </div>
  //   // )
  // })
}

const query = graphql`
  {
    AllRaisedFloors: allAirtable(filter: { table: { eq: "Raised Floor" } }) {
      nodes {
        data {
          nameMn: Name_mongol
          nameEn: Name_english
          Ontslog {
            data {
              nameMn: mn
              nameEn: en
              Icon {
                localFiles {
                  childImageSharp {
                    gatsbyImageData(
                      width: 100
                      placeholder: NONE
                      layout: CONSTRAINED
                    )
                  }
                }
              }
            }
          }
          Type: RF_Type {
            data {
              nameMn: Name_mongol
              nameEn: Name_english
              textMn: Mongol_tailbar
              textEn: English_tailbar
              Image {
                localFiles {
                  childImageSharp {
                    gatsbyImageData(width: 1000, layout: CONSTRAINED)
                  }
                }
              }
              Icon {
                localFiles {
                  childImageSharp {
                    gatsbyImageData(
                      width: 100
                      placeholder: NONE
                      layout: CONSTRAINED
                    )
                  }
                }
              }
            }
          }
          size: RF_Size
          Height
          Achaalal
          mdMn: Description_mongol {
            internal {
              content
            }
          }
          mdEn: Description_english {
            internal {
              content
            }
          }
          New
          ontsloh: Ontsloh_baraa_bolgoh
          Image {
            localFiles {
              childImageSharp {
                gatsbyImageData(
                  width: 1000
                  layout: CONSTRAINED
                  # placeholder: BLURRED
                )
              }
            }
          }
          country: Made_In {
            data {
              mn
              en
              Flag {
                localFiles {
                  childImageSharp {
                    gatsbyImageData(
                      width: 100
                      layout: CONSTRAINED
                      placeholder: NONE
                    )
                  }
                }
              }
            }
          }
          Brand {
            data {
              mn
              en
              Logo {
                localFiles {
                  childImageSharp {
                    gatsbyImageData(
                      width: 200
                      layout: CONSTRAINED
                      placeholder: NONE
                    )
                  }
                }
              }
            }
          }
          Hide
        }
        id
        table
      }
    }
  }
`
