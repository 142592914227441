import React, { useEffect, useState } from "react"
import WallpaperCarousel from "./WallpaperCarousel"
import RailsedFloorCarousel from "./RailsedFloorCarousel"
import OtherProductCarousel from "./OtherProductCarousel"
import SidingCarousel from "./SidingCarousel"
import { WallpaperDescription } from "../graphql/WallpaperMenuQuery"
import { RaisedFloorDesc } from "../graphql/RaisedFloorSmallQueries"
import { Desc } from "../graphql/SidingSmallQueries"
import {
  WallHide,
  FloorHide,
  SidingHide,
  PtileHide,
  CarpetHide,
  CurtainHide,
} from "../graphql/ControlQuery"

const ProductCarousel = () => {
  const [value, setValue] = useState(0)
  const activeStyle =
    "sm:px-4 sm:py-1 font-bold text-black sm:rounded sm:bg-white sm:border border-black antialiased underline sm:no-underline"

  const buttons = [
    {
      name: WallpaperDescription().name,
      component: <WallpaperCarousel />,
      hide: WallHide(),
    },
    {
      name: RaisedFloorDesc()[0].name,
      component: <RailsedFloorCarousel />,
      hide: FloorHide(),
    },
    {
      name: Desc()[0].name,
      component: <SidingCarousel />,
      hide: SidingHide(),
    },
  ].filter(item => !item.hide)

  const [showingSliders, setShowingSliders] = useState(buttons[0].component)

  useEffect(() => {
    setShowingSliders(buttons[value].component)
  }, [value])

  // console.log(buttons[value].component)

  const buttonList = buttons.map((item, index) => {
    return (
      <button
        key={index}
        type="button"
        className={`font-semibold focus:outline-none outline-none px-0 py-1 text-gray-900 sm:px-4 antialiased ${
          index === value ? activeStyle : ""
        }`}
        onClick={() => setValue(index)}
      >
        {item.name}
      </button>
    )
  })

  return (
    <div className="relative z-20 mt-20 overflow-hidden">
      <div className="container flex justify-center gap-2 space-x-2 overflow-hidden antialiased">
        {buttonList}
      </div>
      <div className="max-w-screen-lg mx-auto mt-8 overflow-hidden sm:px-10">
        {/* {buttons[value].component} */}
        {showingSliders}
      </div>
    </div>
  )
}

export default ProductCarousel
