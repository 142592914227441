import React from "react"
import HomePageSlider from "../components/HomePageSlider"
import { All } from "../graphql/WallpaperSliderQuery"
import { WallpaperDescription } from "../graphql/WallpaperMenuQuery"
import { RaisedFloorDesc } from "../graphql/RaisedFloorSmallQueries"
import { Desc } from "../graphql/SidingSmallQueries"
import { HivsentserDesc } from "../graphql/HivsentserSmallQueries"
import { HuldaasDesc } from "../graphql/HuldaasSmallQueries"
import { CurtainDesc } from "../graphql/CurtainSmallQueries"
import {
  WallHide,
  FloorHide,
  SidingHide,
  PtileHide,
  CarpetHide,
  CurtainHide,
} from "../graphql/ControlQuery"

const HeroSwiper = () => {
  const data = [
    !WallHide() && WallpaperDescription(),
    !FloorHide() && RaisedFloorDesc()[0],
    !SidingHide() && Desc()[0],
    !CarpetHide() && HivsentserDesc()[0],
    !PtileHide() && HuldaasDesc()[0],
    !CurtainHide() && CurtainDesc()[0],
  ].filter(Boolean)

  return (
    // <div className="max-w-screen-xl mx-auto overflow-hidden">
    <div className="mx-auto overflow-hidden">
      <HomePageSlider data={data} />
    </div>
  )
}

export default HeroSwiper
