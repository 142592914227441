import React from "react"

import { AllWallpapers } from "../graphql/WallpaperPageFullQuery"
import SwiperProducts from "./SwiperProducts"

const WallpaperCarousel = () => {
  const ontslohBaraanuud = AllWallpapers().filter(
    product => product.ontslohBaraa
  )

  return <SwiperProducts data={ontslohBaraanuud} />
}

export default WallpaperCarousel
