import React, { useContext, useEffect, useRef, useState, Fragment } from "react"
import { GatsbyContext } from "../context/context"
import { Link } from "gatsby"
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/solid"
import SwiperCore, {
  Navigation,
  Pagination,
  A11y,
  Lazy,
  Autoplay,
} from "swiper"
import {
  BadgeNew,
  BadgeHot,
  BadgeBestseller,
  BadgeSale,
  TextMore,
} from "../graphql/SmallQueries"

import { Swiper, SwiperSlide } from "swiper/react"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import QSee from "./QSee"

SwiperCore.use([A11y, Lazy, Autoplay, Navigation])

const SwiperProductLink = ({ data, zoom, link, productTypeProp }) => {
  const totalSliders = data.length
  const {
    isMegaMenuOpen,
    showMega,
    hideMega,
    isModalOpen,
    showModal,
    hideModal,
  } = useContext(GatsbyContext)

  const prevRef = useRef(null)
  const nextRef = useRef(null)

  const swiperRef = useRef(null)

  const [modalOpen, setModalOpen] = useState(false)
  const [slideNumber, setSlideNumber] = useState()

  useEffect(() => {
    if (modalOpen) {
      stopAutoplay()
    }
  }, [modalOpen])

  function handleClick() {
    setModalOpen(!modalOpen)
  }

  const slides = data?.map((item, index) => {
    const {
      id,
      name,
      image,
      slug,
      best,
      hot,
      modern,
      sale,
      newProduct,
      productType,
    } = item ?? undefined

    const badges = (
      <>
        {newProduct && (
          <div className="absolute opacity-70 -top-1.25 -left-1.25">
            <span className="flex w-12 h-12">{BadgeNew()[0].icon}</span>
          </div>
        )}
        <div
          className={`absolute flex justify-end top-1  ${
            link ? "inset-y-0 right-4" : "right-1 inset-x-0"
          }`}
        >
          {hot && (
            <div className=" opacity-80">
              <span className="flex w-6 h-6">{BadgeHot()[0].icon}</span>
            </div>
          )}
          {sale && (
            <div className=" opacity-80">
              <div className="relative">
                <span className="flex w-10 h-8">{BadgeSale()[0].icon}</span>
                <span className="absolute text-xs text-white inset-2 left-2.5">
                  {sale}
                </span>
              </div>
            </div>
          )}
          {best && (
            <div className=" opacity-80">
              <div className="relative">
                <span className="flex w-8 h-8">
                  {BadgeBestseller()[0].icon}
                </span>
              </div>
            </div>
          )}
        </div>
      </>
    )

    return (
      <div key={id}>
        <SwiperSlide className="group" key={index}>
          <div className="relative">
            <div className="h-2"></div>
            <Link to={`/${productType}/${slug}`} className="relative inset-0">
              <div
                className="flex object-cover w-full h-48 overflow-hidden rounded group-hover:saturate-0 filter"
                style={{
                  WebkitBackfaceVisibility: "hidden",
                  MozBackfaceVisibility: "hidden",
                  WebkitTransform: "translate3d(0, 0, 0)",
                  MozTransform: "translate3d(0, 0, 0)",
                }}
              >
                {image && image}
                <div className="absolute inset-0 w-full h-full">&nbsp;</div>
                <div className="absolute inset-x-0 bottom-0">
                  {name && (
                    <div className="flex justify-center px-2 py-1 text-sm font-semibold text-center bg-white bg-opacity-70">
                      {name}
                    </div>
                  )}
                </div>
              </div>
              {badges}
            </Link>
          </div>
          <div className="h-2"></div>
        </SwiperSlide>
      </div>
    )
  })

  function stopAutoplay() {
    swiperRef.current.swiper.autoplay.stop()
  }

  useEffect(() => {
    if (isModalOpen || isMegaMenuOpen || zoom) {
      stopAutoplay()
    } else {
      swiperRef.current.swiper.autoplay.start()
    }
  }, [isMegaMenuOpen, isModalOpen, zoom])

  const customBreakpoints1 = {
    640: {
      slidesPerView: 1,
      spaceBetween: 20,
    },
    768: {
      slidesPerView: 1,
      spaceBetween: 20,
    },
    1024: {
      slidesPerView: 1,
      spaceBetween: 20,
    },
  }

  const customBreakpoints2 = {
    640: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    768: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    1024: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
  }

  const customBreakpoints3 = {
    640: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
    768: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
    1024: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
  }

  const customBreakpoints4 = {
    640: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
    768: {
      slidesPerView: 4,
      spaceBetween: 20,
    },
    1024: {
      slidesPerView: 4,
      spaceBetween: 20,
    },
  }

  const customBreakpoints5 = {
    640: {
      slidesPerView: 3,
      spaceBetween: 10,
    },
    768: {
      slidesPerView: 4,
      spaceBetween: 10,
    },
    1024: {
      slidesPerView: 5,
      spaceBetween: 10,
    },
  }

  const slidesPerView =
    totalSliders === 1
      ? customBreakpoints1
      : totalSliders === 2
      ? customBreakpoints2
      : totalSliders === 3
      ? customBreakpoints3
      : totalSliders === 4
      ? customBreakpoints4
      : customBreakpoints5

  const innerWidthTailwind =
    totalSliders === 1
      ? "sm:max-w-xs"
      : totalSliders === 2
      ? "max-w-2xl"
      : totalSliders === 3
      ? "lg:w-4/5 xl:max-w-4xl"
      : totalSliders === 4
      ? "max-w-5xl mx-auto"
      : ""

  return (
    <div>
      {modalOpen && (
        <QSee
          data={data[slideNumber]}
          modalOpen={modalOpen}
          modalClose={() => setModalOpen(false)}
          setModalOpen={setModalOpen}
        />
      )}
      <div
        className={`${
          isMegaMenuOpen ? "opacity-20" : ""
        } ${innerWidthTailwind}`}
        onMouseEnter={() => stopAutoplay()}
        onMouseLeave={() => swiperRef.current.swiper.autoplay.start()}
      >
        <Swiper
          ref={swiperRef}
          spaceBetween={10}
          slidesPerView={2}
          lazy={true}
          watchOverflow={true}
          breakpoints={slidesPerView}
          navigation={{
            prevEl: prevRef.current ? prevRef.current : undefined,
            nextEl: nextRef.current ? nextRef.current : undefined,
          }}
          onInit={swiper => {
            swiper.params.navigation.prevEl = prevRef.current
            swiper.params.navigation.nextEl = nextRef.current
            swiper.navigation.update()
          }}
        >
          {slides}
          <div
            className={`items-center justify-center flex ${
              totalSliders === 1
                ? "hidden"
                : totalSliders === 2
                ? "sm:hidden"
                : totalSliders === 3
                ? "md:hidden"
                : totalSliders === 4
                ? "lg:hidden"
                : totalSliders === 5
                ? "xl: hidden"
                : ""
            }`}
          >
            <button ref={prevRef} className="outline-none focus:outline-none">
              <ChevronLeftIcon className="w-12 h-12 p-2 text-gray-400 rounded-full outline-none hover:text-gray-900 hover:bg-gray-100 focus:outline-none" />
            </button>
            <button ref={nextRef} className="outline-none focus:outline-none">
              <ChevronRightIcon className="w-12 h-12 p-2 text-gray-400 rounded-full outline-none hover:text-gray-900 hover:bg-gray-100 focus:outline-none" />
            </button>
          </div>
        </Swiper>
      </div>
    </div>
  )
}

export default SwiperProductLink
