import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import ProductCarousel from "../components/ProductCarousel"
import Banner from "../components/Banner"
import ProductCatalog from "../components/ProductCatalog"
import { MainBanner } from "../graphql/BannerQuery"
import HeroSwiper from "../components/HeroSwiper"
import Partners from "../components/Partners"
import LogoSlider from "../components/LogoSlider"
import { HomePageText } from "../graphql/QueryById"

const IndexPage = () => {
  return (
    <>
      <Layout dots svgHide>
        <SEO title='Nippon Standard' />
        {/* <SEO title={HomePageText()} /> */}
        <HeroSwiper />
        <ProductCarousel />
        {/* {MainBanner().show && <Banner data={MainBanner()} />} */}
        {/* <ProductCatalog /> */}
        <LogoSlider />
      </Layout>
    </>
  )
}

export default IndexPage
