import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import ReactMarkdown from "react-markdown"
import { CurrentLanguage } from "../components/MenuItems"
import Title from "../components/Title"
import { useTranslation } from "react-i18next"

export const AllWorks = () => {
  const currentLanguage = CurrentLanguage()
  const mn = currentLanguage === "mn"
  const { AllWorks } = useStaticQuery(query)


  const path = AllWorks?.nodes ?? undefined
  const works = path
    ? path
        .map((item, index) => {
          const path = item?.data ?? undefined
          const nameMn = path?.nameMn ?? undefined
          const nameEn = path?.nameEn ?? undefined
          const name = mn ? nameMn : nameEn

          const comMn = path?.comMn ?? undefined
          const comEn = path?.comEn ?? undefined
          const company = mn ? comMn : comEn

          const date = path?.Date ?? undefined

          const supplyPath = path?.Supply ?? undefined
          const supply = supplyPath?.map((item, index) => {
            const path = item?.data ?? undefined
            const nameMn = path?.nameMn ?? undefined
            const nameEn = path?.nameEn ?? undefined
            const name = mn ? nameMn : nameEn

            const iconPath = path?.Icon ?? undefined
            const iconSharp = iconPath
              ? getImage(iconPath.localFiles[0])
              : undefined
            const icon = iconSharp ? (
              <GatsbyImage image={iconSharp} alt={name} className="w-full" />
            ) : undefined

            return { name, icon }
          })

          const projectLogoPath = path?.proLogo ?? undefined
          const proLogoSharp = projectLogoPath
            ? getImage(projectLogoPath.localFiles[0])
            : undefined
          const projectLogo = proLogoSharp ? (
            <GatsbyImage
              image={proLogoSharp}
              alt={`Project ${name}`}
              className="w-full"
            />
          ) : undefined

          const imagePath = path?.Image ? path.Image?.localFiles : undefined
          const images = imagePath?.map((item, index) => {
            const sharp = item ? getImage(item) : undefined
            const image = sharp ? (
              <GatsbyImage
                image={sharp}
                alt={`${name} image ${index}`}
                className="w-full"
              />
            ) : undefined
            return image
          })

          const companyLogoPath = path?.comLogo ?? undefined
          const comLogoSharp = companyLogoPath
            ? getImage(companyLogoPath.localFiles[0])
            : undefined
          const companyLogo = comLogoSharp ? (
            <GatsbyImage
              image={comLogoSharp}
              alt={company}
              // className="w-full"
            />
          ) : undefined

          return {
            name,
            company,
            supply,
            images,
            date,
            projectLogo,
            companyLogo,
          }
        })
        .sort(function (a, b) {
          return b.date - a.date
        })
    : undefined

  return works
}

const query = graphql`
  {
    AllWorks: allAirtable(filter: { table: { eq: "Works" } }) {
      nodes {
        data {
          nameMn: Name_mongol
          nameEn: Name_english
          Supply {
            data {
              nameMn: mn
              nameEn: en
              Icon {
                localFiles {
                  childImageSharp {
                    gatsbyImageData(
                      width: 100
                      layout: CONSTRAINED
                      placeholder: NONE
                    )
                  }
                }
              }
            }
          }
          comMn: Company_name_mongol
          comEn: Company_name_english
          Image {
            localFiles {
              childImageSharp {
                gatsbyImageData(
                  width: 400
                  layout: CONSTRAINED
                  placeholder: BLURRED
                )
              }
            }
          }
          Date
          proLogo: Project_Logo {
            localFiles {
              childImageSharp {
                gatsbyImageData(
                  width: 200
                  placeholder: NONE
                  layout: CONSTRAINED
                )
              }
            }
          }
          comLogo: Company_Logo {
            localFiles {
              childImageSharp {
                gatsbyImageData(
                  width: 200
                  layout: CONSTRAINED
                  placeholder: NONE
                )
              }
            }
          }
        }
      }
    }
  }
`
